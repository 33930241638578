import React from 'react';
import { Button, Flex, Text, useColorModeValue, Grid, GridItem, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useAxios from 'axios-hooks';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import InputController from 'components/Form/InputController';
import { API_ROUTES, PROVIDER, ProviderOptions } from 'utils/constant';
import SelectController from 'components/Form/SelectController';
import omit from 'lodash/omit';

const schema = yup.object().shape({
  provider: yup.mixed().nullable().required('Vui lòng chọn nhà cung cấp'),
  phone: yup.string().nullable().required('Vui lòng nhập số điện thoại'),
  otp: yup.string().nullable().required('Vui lòng nhập OTP'),
});

const SendVoiceOtp = () => {
  const toast = useToast();
  const textColor = useColorModeValue('gray.700', 'white');
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const [{ loading }, SendVoiceOtpTest] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.SendVoiceOtpTest,
    },
    {
      manual: true,
    }
  );
  const [{ loading: loadingDtel }, SendVoiceOtpDtel] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.SendVoiceOtpDtel,
    },
    {
      manual: true,
    }
  );
  const [{ loading: loadingTps }, SendVoiceOtpTPS] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.SendVoiceOtpTps,
    },
    {
      manual: true,
    }
  );
  const [{ loading: loadingZns }, SendVoiceOtpZns] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.SendVoiceOtpZns,
    },
    {
      manual: true,
    }
  );

  const onSubmit = values => {
    if (values?.provider?.value === PROVIDER.DTEL) {
      SendVoiceOtpDtel({ data: omit(values, ["provider"]) })
        .then(() => {
          toast({
            title: 'Send Voice OTP successfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          reset({
            otp: '',
            phone: '',
            provider: undefined
          });
        })
        .catch(error => {
          toast({
            title: error?.response?.data?.errors?.errors?.[0]?.msg || error?.response?.data?.msg || 'The template save not successfully',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
      return;
    }
    if (values?.provider?.value === PROVIDER.TPS) {
      SendVoiceOtpTPS({ data: omit(values, ["provider"]) })
        .then(() => {
          toast({
            title: 'Send Voice OTP successfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          reset({
            otp: '',
            phone: '',
            provider: undefined
          });
        })
        .catch(error => {
          toast({
            title: error?.response?.data?.errors?.errors?.[0]?.msg || error?.response?.data?.msg || 'The template save not successfully',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
      return;
    }
    if (values?.provider?.value === PROVIDER.ZNS) {
      SendVoiceOtpZns({ data: omit(values, ["provider"]) })
        .then(() => {
          toast({
            title: 'Send Voice OTP successfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          reset({
            otp: '',
            phone: '',
            provider: undefined
          });
        })
        .catch(error => {
          toast({
            title: error?.response?.data?.errors?.errors?.[0]?.msg || error?.response?.data?.msg || 'The template save not successfully',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
      return;
    }
    SendVoiceOtpTest({ data: omit(values, ["provider"]) })
      .then(() => {
        toast({
          title: 'Send Voice OTP successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        reset({
            otp: '',
            phone: '',
            provider: undefined
        });
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.errors?.[0]?.msg || error?.response?.data?.msg || 'The template save not successfully',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };
  return (
    <Card mt={{ base: '120px', md: '75px' }} overflowX={{ sm: 'scroll', xl: 'hidden' }}>
      <CardHeader p="6px 0px 14px 0px">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          Send Voice OTP
        </Text>
      </CardHeader>
      <CardBody>
        <Grid templateColumns="repeat(2, 1fr)">
          <GridItem w="100%">
            <Flex alignItems="center" flexDirection={'column'} gap={6}>
              <SelectController styleContainer={{ pt: '4' }} control={control} name="provider" label="Provider" options={ProviderOptions} />
              <InputController
                styleContainer={{ display: 'flex', alignItems: 'center' }}
                styleBoxInput={{ flexShrink: '1', flexGrow: '1', flexBasis: '0', marginRight: 2 }}
                styleLabel={{ m: '0' }}
                control={control}
                type="number"
                name="phone"
                label="Phone"
              />
              <InputController
                styleContainer={{ display: 'flex', alignItems: 'center' }}
                styleBoxInput={{ flexShrink: '1', flexGrow: '1', flexBasis: '0', marginRight: 2 }}
                styleLabel={{ m: '0' }}
                control={control}
                name="otp"
                label="OTP"
              />
            </Flex>

            <Flex mt="4" justifyContent="end">
              <Button variant="primary" maxH="30px" size="sm" isLoading={loading || loadingDtel || loadingTps || loadingZns} onClick={handleSubmit(onSubmit)} mr={2}>
                Send
              </Button>
            </Flex>
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default SendVoiceOtp;
