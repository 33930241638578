import { useMemo } from "react";
import { isJsonString } from "utils/helpers";

/**
 *
 * WithAuthorization
 *
 */
function AuthorizationGroupWrapper({
  children,
  allowedGroups,
  disabled = false,
}) {
  const userInfoLocalStorage = localStorage.getItem('isUserShow');
  const user = isJsonString(userInfoLocalStorage);

  const userGroups = user?.groups?.map(gr => gr.name) || [];
  const hasRole = (allowedGroups || []).some(group => userGroups.includes(group));
  
  if (user?.level === '1' || hasRole || (!allowedGroups && !userGroups.length) || !allowedGroups?.length) {
    return children;
  } else {
    if (disabled) {
      return children;
    }
    return null;
  }
}

export default AuthorizationGroupWrapper;
