import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Input, Text, useToast, useColorModeValue } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { API_ROUTES } from 'utils/constant';
import { StorageKey } from 'utils/constant';
import useCopyToClipboard from 'hooks/use-copy-to-clipboard';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

const AuthZaloUrl = ({oa_id}) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const toast = useToast();
  const [valueInput, setValueInput] = useState('');
  const [, copy] = useCopyToClipboard();
  const [{ data, loading: getAuthLoading }, getZaloAuth] = useAxios(
    {
      url: API_ROUTES.ZaloAuth,
    },
    { manual: true }
  );
  const [{ loading: createLoading }, createZaloAuthApi] = useAxios(
    {
      url: API_ROUTES.ZaloAuth,
      method: 'post',
    },
    { manual: true }
  );
  useEffect(() => {
    let urlZaloAuth;
    if(oa_id){
       urlZaloAuth = localStorage.getItem(StorageKey.UrlZaloAuthDetail);
    }else{
      urlZaloAuth = localStorage.getItem(StorageKey.UrlZaloAuth);
    }
   
    setValueInput(urlZaloAuth);
  }, []);

  const onGetZaloAuth = () => {
    console.log(oa_id)
    if(oa_id){
      createZaloAuthApi({ data: { oa_id } })
        .then(res => {
          localStorage.setItem(StorageKey.UrlZaloAuthDetail, res?.data?.url);
          setValueInput(res?.data?.url);
        })
        .catch(error => {
          toast({
            title: error?.response?.data?.errors?.errors?.[0]?.msg || error?.response?.data?.msg || 'Get url successfully',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        });

      return
    }
    // getZaloAuth()
    //   .then(res => {
    //     localStorage.setItem(StorageKey.UrlZaloAuth, res?.data?.url);
    //     setValueInput(res?.data?.url);
    //   })
    //   .catch(error => {
    //     toast({
    //       title: error?.response?.data?.errors?.errors?.[0]?.msg || error?.response?.data?.msg || 'Get url successfully',
    //       status: 'error',
    //       duration: 9000,
    //       isClosable: true,
    //     });
    //   });
  };

  const openUrlZaloAuth = () => {
    window.open(valueInput, '_blank', 'noopener,noreferrer');
  };

  const copyUrlZaloAuth = () => {
    copy(valueInput);
    toast({
      title: 'Copy Success',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  return (
    <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
      <CardHeader p="6px 0px 14px 0px">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          Auth Zalo Url
        </Text>
      </CardHeader>
      <CardBody>
        <Box direction="column">
          <Flex alignItems="center">
            <Button variant="primary" maxH="30px" my="10px" isLoading={getAuthLoading} onClick={onGetZaloAuth}>
              Get Url
            </Button>
          </Flex>
          {!!valueInput && (
            <Flex alignItems="center">
              <Input value={valueInput} />
              <Button variant="primary" maxH="30px" m="10px" onClick={openUrlZaloAuth}>
                Open
              </Button>
              <Button variant="primary" maxH="30px" m="10px" onClick={copyUrlZaloAuth}>
                Copy
              </Button>
            </Flex>
          )}
        </Box>
      </CardBody>
    </Card>
  );
};

export default AuthZaloUrl;
