import React from 'react';
import { Flex, IconButton, Td, Text, Tr, useColorModeValue, useToast } from '@chakra-ui/react';
import { formatDate } from 'utils/helpers';
import { DeleteIcon } from '@chakra-ui/icons';
import { ModalType } from 'utils/constant';

function Row({ ipDetail, isLast, handelUpdate }) {
  const toast = useToast();
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Tr>
      <Td minWidth={{ sm: '100px' }} pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
            {ipDetail?.ip}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <IconButton
          p={2}
          bg="transparent"
          onClick={() => {
            handelUpdate(ipDetail, ModalType.Delete);
          }}
        >
          <DeleteIcon color="red.400" />
        </IconButton>
      </Td>
    </Tr>
  );
}

export default Row;
