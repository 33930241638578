import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import useAxios from 'axios-hooks';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { API_ROUTES } from 'utils/constant';
import { ModalType } from 'utils/constant';
import { schemaTest } from 'utils/validation';

const defaultValues = {
  ip: '',
};

const AddIPModal = ({ isOpen, channelDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [{ loading: createLoading }, createIPSApi] = useAxios(
    {
      url: API_ROUTES.IPS,
      method: 'post',
    },
    { manual: true }
  );
  const schema = yup.object().shape({
    ip: yup
      .string()
      .required('Please enter the IP (required)')
      .test('isValidIP', 'Please enter a valid IP', value => {
        return schemaTest.isValidIP(value);
      }),
  });
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => () => reset(), []);

  const handleSuccess = () => {
    toast({
      title: 'The IP has been added',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose?.(ModalType.Add);
  };

  const handleError = error => {
    toast({
      title: error?.response?.data?.errors?.errors?.[0]?.msg || error?.response?.data?.msg || 'Add IP not successfully',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    toast.closeAll();

    createIPSApi({
      data: values,
    })
      .then(res => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">Add IP</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <InputController control={control} name="ip" label="IP" isRequired />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.(ModalType.Add);
              }}
            >
              Cancel
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={createLoading} onClick={handleSubmit(onSubmit)}>
              Add
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AddIPModal;
