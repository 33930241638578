import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Table, Tbody, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { initialFilter } from 'utils/constant';
import Pagination from 'components/Pagination/Pagination';
import { API_ROUTES } from 'utils/constant';
import Row from './components/Row';

const Template = () => {
  const [filter, setFilter] = useState({ ...initialFilter });
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const [{ data }] = useAxios({
    url: API_ROUTES.AllTemplate,
    params: filter,
  });

  return (
    <>
      <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Template List
            </Text>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <Th pl="0px" borderColor={borderColor} color="gray.400">
                    Template ID
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Template name
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Status
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Create
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.data?.map((row, index, arr) => (
                  <Row key={row.id} templateDetail={row} isLast={index === arr.length - 1 ? true : false} />
                ))}
              </Tbody>
            </Table>
            {/* <Flex justifyContent={'flex-end'}>
              <Pagination
                page={data?.pagination?.page}
                pageLength={data?.pagination?.pageSize}
                totalRecords={data?.pagination?.count}
                onPageChange={(page, pageLength) => {
                  setFilter({
                    ...filter,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  });
                }}
              />
            </Flex> */}
          </CardBody>
        </Card>
      </Box>
    </>
  );
};

export default Template;
