import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { initialFilter } from 'utils/constant';
import Pagination from 'components/Pagination/Pagination';
import { API_ROUTES } from 'utils/constant';
import Row from './components/Row';
import AddIPModal from './components/AddIPModal';
import { ModalType } from 'utils/constant';
import DeleteIPModal from './components/DeleteIPModal';

const WhitelistIP = () => {
  const [filter, setFilter] = useState({ ...initialFilter });
  const [ipDetail, setIpDetail] = useState(null);
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen: isOpenAddModal, onOpen: onOpenAddModal, onClose: onCloseAddModal } = useDisclosure();
  const { isOpen: isOpenDeleteModal, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenAddModal,
      [ModalType.Delete]: onOpenDeleteModal,
    }),
    [onOpenAddModal, onOpenDeleteModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseAddModal,
      [ModalType.Delete]: onCloseDeleteModal,
    }),
    [onCloseAddModal, onCloseDeleteModal]
  );

  const [{ data, loading }, refetch] = useAxios(
    {
      url: API_ROUTES.IPS,
      params: filter,
    },
    {
      useCache: false,
    }
  );

  const handelUpdate = (customerDetail, modalType) => {
    setIpDetail(customerDetail);
    openModal?.[modalType]?.();
  };

  const handelCloseModal = modalType => {
    setIpDetail(null);
    closeModal?.[modalType]?.();
  };

  return (
    <>
      <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Whitelist IP
            </Text>
            <Button variant="primary" maxH="30px" my="10px" onClick={onOpenAddModal}>
              Add IP
            </Button>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <Th pl="0px" borderColor={borderColor} color="gray.400">
                    IP
                  </Th>
                  <Th borderColor={borderColor} color="gray.400" />
                </Tr>
              </Thead>
              <Tbody>
                {data?.data?.map((row, index, arr) => (
                  <Row key={row._id} ipDetail={row} isLast={index === arr.length - 1 ? true : false} handelUpdate={handelUpdate} />
                ))}
                {isEmpty(data?.data) && !loading && (
                  <Tr>
                    <Td colSpan="2">
                      <Box textAlign="center" height="200px" pt="24px">
                        No data
                      </Box>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
            <Flex justifyContent={'flex-end'}>
              {!isEmpty(data?.data) && (
                <Pagination
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              )}
            </Flex>
          </CardBody>
        </Card>
      </Box>
      {isOpenAddModal && <AddIPModal isOpen={isOpenAddModal} onClose={handelCloseModal} refetchData={refetch} />}
      {isOpenDeleteModal && (
        <DeleteIPModal ipDetail={ipDetail} isOpen={isOpenDeleteModal} onClose={handelCloseModal} refetchData={refetch} />
      )}
    </>
  );
};

export default WhitelistIP;
