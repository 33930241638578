import React, { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { Flex, Grid, SimpleGrid, Stat, StatLabel, StatNumber, Text, useColorModeValue } from '@chakra-ui/react';
import { EmailIcon, ViewIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import Card from 'components/Card/Card.js';
import { checkLogin } from '../../utils/authentication';
import { API_ROUTES } from 'utils/constant';
import { formatDate } from 'utils/helpers';
import SMSDailyChart from 'components/SMSDailyChart/SMSDailyChart';
import { BrandMailKey } from 'utils/constant';
import { getInitFilerChart } from 'utils/helpers';
import { PersonIcon } from 'components/Icons/Icons';
import IconBox from 'components/Icons/IconBox';

const initFiler = getInitFilerChart();

export default function Dashboard() {
  const iconBlue = useColorModeValue('blue.500', 'blue.500');
  const iconBoxInside = useColorModeValue('white', 'white');
  const textColor = useColorModeValue('gray.700', 'white');
  const isLoggedIn = checkLogin();
  const history = useHistory();

  const [{ data: statistical888B }, getStatistical888B] = useAxios(
    {
      url: API_ROUTES.SMSDaily,
    },
    { manual: true }
  );
  const [{ data: statistical8Day }, getStatistical8Day] = useAxios(
    {
      url: API_ROUTES.SMSDaily,
    },
    { manual: true }
  );
  const [{ data: overviewSMSData }, getOverviewSMS] = useAxios(
    {
      url: API_ROUTES.OverviewSMS,
      params: {
        brand: 'VienThongMN',
      },
    },
    { manual: true, useCache: false }
  );
  console.log('🚀 ~ file: Dashboard.js:40 ~ Dashboard ~ overviewSMSData:', overviewSMSData);

  useEffect(() => {
    getStatistical888B({
      params: {
        startDate: formatDate(initFiler.startDate, 'YYYY-MM-DD'),
        endDate: formatDate(initFiler.endDate, 'YYYY-MM-DD'),
        brand: BrandMailKey.Ebbb,
      },
    });
    getStatistical8Day({
      params: {
        startDate: formatDate(initFiler.startDate, 'YYYY-MM-DD'),
        endDate: formatDate(initFiler.endDate, 'YYYY-MM-DD'),
        brand: BrandMailKey.Eday,
      },
    });
    getOverviewSMS();
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/signin');
    }
  }, [isLoggedIn, history]);

  const handleGetNewData888D = (startDate, endDate) => {
    getStatistical888B({
      params: {
        startDate: formatDate(startDate, 'YYYY-MM-DD'),
        endDate: formatDate(endDate, 'YYYY-MM-DD'),
        brand: BrandMailKey.Ebbb,
      },
    });
  };

  const handleGetNewData8Day = (startDate, endDate) => {
    getStatistical8Day({
      params: {
        startDate: formatDate(startDate, 'YYYY-MM-DD'),
        endDate: formatDate(endDate, 'YYYY-MM-DD'),
        brand: BrandMailKey.Eday,
      },
    });
  };

  return (
    <>
      <Flex flexDirection="column" pt={{ base: '120px', md: '75px' }}>
        <SimpleGrid columns={{ sm: 1, md: 3, xl: 3 }} spacing="24px" mb="20px">
          <Card minH="125px">
            <Flex direction="column">
              <Flex flexDirection="row" align="center" justify="center" w="100%" mb="25px">
                <Stat me="auto">
                  <StatLabel fontSize="xs" color="gray.400" fontWeight="bold" textTransform="uppercase">
                    Total SMS
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                      {overviewSMSData?.data?.totalSms || 0}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} bg={iconBlue}>
                  <PersonIcon h={'24px'} w={'24px'} color={iconBoxInside} />
                </IconBox>
              </Flex>
              <Text color="gray.400" fontSize="sm">
                <Text
                  as="span"
                  cursor="pointer"
                  color="blue.400"
                  textDecoration="underline"
                  _hover={{ textDecoration: 'none', opacity: '.7' }}
                  onClick={() => {
                    history.push('/admin/campaign');
                  }}
                >
                  Chi tiết
                </Text>
              </Text>
            </Flex>
          </Card>
          <Card minH="125px">
            <Flex direction="column">
              <Flex flexDirection="row" align="center" justify="center" w="100%" mb="25px">
                <Stat me="auto">
                  <StatLabel fontSize="xs" color="gray.400" fontWeight="bold" textTransform="uppercase">
                    SMS successfully
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                      {overviewSMSData?.data?.totalSentSuccess || 0}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} bg={iconBlue}>
                  <EmailIcon h={'24px'} w={'24px'} color={iconBoxInside} />
                </IconBox>
              </Flex>
              <Text color="gray.400" fontSize="sm">
                <Text as="span" color="green.400" fontWeight="bold">
                  {overviewSMSData?.data?.ratioSuccessSent || 0}%
                </Text>
              </Text>
            </Flex>
          </Card>
          <Card minH="125px">
            <Flex direction="column">
              <Flex flexDirection="row" align="center" justify="center" w="100%" mb="25px">
                <Stat me="auto">
                  <StatLabel fontSize="xs" color="gray.400" fontWeight="bold" textTransform="uppercase">
                    SMS fail
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                      {overviewSMSData?.data?.totalSentFail || 0}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox borderRadius="50%" as="box" h={'45px'} w={'45px'} bg={iconBlue}>
                  <ViewIcon h={'24px'} w={'24px'} color={iconBoxInside} />
                </IconBox>
              </Flex>
              <Text color="gray.400" fontSize="sm">
                <Text as="span" color="red.400" fontWeight="bold">
                  {overviewSMSData?.data?.ratioFailSent || 0}%
                </Text>
              </Text>
            </Flex>
          </Card>
        </SimpleGrid>
        <Grid templateColumns={{ sm: '1fr' }} templateRows={{ lg: 'repeat(2, auto)' }} gap="20px">
          <Card p="0px" maxW={{ sm: '320px', md: '100%' }}>
            <SMSDailyChart brand={BrandMailKey.Eday} dataChart={statistical8Day?.data || []} getNewData={handleGetNewData8Day} />
          </Card>
          <Card p="0px" maxW={{ sm: '320px', md: '100%' }}>
            <SMSDailyChart brand={BrandMailKey.Ebbb} dataChart={statistical888B?.data || []} getNewData={handleGetNewData888D} />
          </Card>
        </Grid>
      </Flex>
    </>
  );
}
