import React from 'react';
import { Flex, Td, Text, Tr, useColorModeValue, useToast } from '@chakra-ui/react';
import { formatDate } from 'utils/helpers';
import { Groups, VENDOR } from 'utils/constant';
import AuthorizationGroupWrapper from 'components/AuthorizationGroupWrapper/AuthorizationGroupWrapper';

function Row({ smsDetail, isLast }) {
  const toast = useToast();
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Tr>
      <Td width="100px" pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={titleColor} fontWeight="bold" noOfLines={2} maxWidth={{ sm: '150px' }}>
            {smsDetail?.callid}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" noOfLines={2}>
            {smsDetail?.phone || ''}
          </Text>
        </Flex>
      </Td>
      {/* <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" noOfLines={2}>
            {smsDetail?.otp || ''}
          </Text>
        </Flex>
      </Td> */}
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {smsDetail?.vendor === VENDOR.ZALO ? 'ZNS' : smsDetail?.callerNumber || ''}
          </Text>
        </Flex>
      </Td>
      <AuthorizationGroupWrapper allowedGroups={[Groups.GROUP_ADMIN]}>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {smsDetail?.vendor}
            </Text>
          </Flex>
        </Td>
      </AuthorizationGroupWrapper>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={{ sm: '150px' }}>
            {smsDetail?.vendor === VENDOR.ZALO ? 'ĐÃ GỬI' : smsDetail?.callstatus ? 'ĐÃ THƯC HIỆN CUỘC GỌI' : 'ĐANG GỌI' || ''}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {smsDetail?.callstatus}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" noOfLines={2}>
            {smsDetail?.billsec}
          </Text>
        </Flex>
      </Td>
      <Td minWidth={{ sm: '100px' }} borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={{ sm: '100px' }}>
            {formatDate(smsDetail?.createdAt, 'DD-MM-YYYY HH:mm:ss')}
          </Text>
        </Flex>
      </Td>
      <Td minWidth={{ sm: '100px' }} borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={{ sm: '100px' }}>
            {smsDetail?.updatedAt ? formatDate(smsDetail?.updatedAt, 'DD-MM-YYYY HH:mm:ss') : ''}
          </Text>
        </Flex>
      </Td>
    </Tr>
  );
}

export default Row;
