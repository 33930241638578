import React from 'react';
import { Flex, Td, Text, Tr, useColorModeValue, useToast } from '@chakra-ui/react';
import { formatDate } from 'utils/helpers';
import moment from 'moment';

function Row({ smsDetail, isLast }) {
  const toast = useToast();
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Tr>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" noOfLines={2}>
            {smsDetail?.phone || ''}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={{ sm: '150px' }}>
            {smsDetail?.statusCode === 0 ? 'ĐÃ GỬI' : 'THẤT BẠI' || ''}
          </Text>
        </Flex>
      </Td>
      <Td minWidth={{ sm: '100px' }} borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={{ sm: '100px' }}>
            {formatDate(smsDetail?.createdAt, 'DD-MM-YYYY HH:mm:ss')}
          </Text>
        </Flex>
      </Td>
      <Td minWidth={{ sm: '100px' }} borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={{ sm: '100px' }}>
            {smsDetail?.sentTime ? moment(smsDetail?.sentTime).format('DD-MM-YYYY HH:mm:ss') : ''}
          </Text>
        </Flex>
      </Td>
    </Tr>
  );
}

export default Row;
