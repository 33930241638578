import React from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import AuthZaloUrl from 'views/Dashboard/Setting/Components/AuthZaloUrl';
import { useParams } from 'react-router-dom';
import { API_ROUTES } from 'utils/constant';
import useAxios from 'axios-hooks';
import Loading from 'components/Layout/Loading';
import DetailOA from './DetailOA';
const ZaloOADetail = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const params = useParams();
  const { id } = params || {};
  const [{ data, loading }, refetch] = useAxios(
    {
      url: `${API_ROUTES.ZaloOADetail}/${id}`,
    },
    {
      useCache: false,
    }
  );
  return (
    <>
      <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
        {loading ? (
          <Loading />
        ) : (
          <>
            <AuthZaloUrl oa_id={data?.data?.oa_id}/>
            <DetailOA data={data?.data} />
          </>
        )}
      </Box>
    </>
  );
};

export default ZaloOADetail;
