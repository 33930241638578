import React from 'react';
import { Flex, IconButton, Td, Text, Tr, useColorModeValue, useToast } from '@chakra-ui/react';
import { BiDetail } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { DeleteIcon } from '@chakra-ui/icons';
import useAxios from 'axios-hooks';
import { API_ROUTES } from 'utils/constant';
function Row({ detail, isLast, refetchData }) {
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const toast = useToast();
  const history = useHistory();

  const [{},deleteOA] = useAxios(
    {
      url: API_ROUTES.DeleteOA,
      method: 'post',
    },
    { manual: true }
  );

  const handleSuccess = () => {
    toast({
      title: 'The OA has been deleted',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
  };

  const handleError = error => {
    toast({
      title: error?.response?.data?.errors?.errors?.[0]?.msg || error?.response?.data?.msg || 'Deleted OA not successfully',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const handleDeletOA = (e, oa_id) => {
    e.stopPropagation();
    if (window.confirm('Are you sure to remove this OA')) {
      deleteOA({
        data: {
          oa_id
        },
      })
        .then(res => {
          handleSuccess();
        })
        .catch(error => {
          handleError(error);
        });
    }
  };
  return (
    <Tr>
      <Td pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={titleColor} fontWeight="bold" noOfLines={2} >
            {detail?.oa_id}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" noOfLines={2}>
            {detail?.oa_name}
          </Text>
        </Flex>
      </Td>
      <Td pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold"  noOfLines={2}>
            {detail?.status === 0 ? "ACTIVE" : "INACTIVE"}
          </Text>
        </Flex>
      </Td> <Td pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold"  noOfLines={2}>
          <IconButton
              p={2}
              bg="transparent"
              onClick={()=>history.push(`zalo-official-account/${detail?.oa_id}`)}
              color={undefined}
            >
              <BiDetail />
            </IconButton>
            <IconButton
              p={2}
              bg="transparent"
              onClick={(e) => handleDeletOA(e, detail?.oa_id)}
              color={undefined}
            >
              <DeleteIcon color="red.400"/>
            </IconButton>
          </Text>
        </Flex>
      </Td>
    </Tr>
  );
}

export default Row;
