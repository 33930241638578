import React, { useEffect } from 'react';
import { Button, Flex, Text, useToast, useColorModeValue, Grid, GridItem } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { API_ROUTES } from 'utils/constant';
import CheckboxController from 'components/Form/CheckboxController';

const SettingConfig = () => {
  const toast = useToast();
  const textColor = useColorModeValue('gray.700', 'white');
  const { control, setValue, handleSubmit, reset, watch } = useForm({
  });
  const [{ loading: getSettingConfigLoading }, getSettingConfig] = useAxios(
    {
      url: API_ROUTES.SettingConfig,
    },
    {
      manual: true,
    }
  );
  const [{ loading }, saveSettingConfig] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.SettingConfig,
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    getSettingConfig().then(res => {
      const data = res?.data?.data;
      if (!isEmpty(data)) {
        reset({ zns: data?.isDisabledZns, dtel: data?.isDisabledDtel, tps: data?.isDisabledTps });
      }
    });
  }, []);

  const onSubmit = values => {
    saveSettingConfig({ data: {
      isDisabledZns: values?.zns,
      isDisabledDtel: values?.dtel,
      isDisabledTps: values?.tps,
    } })
      .then(() => {
        toast({
          title: 'The setting config save successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        getSettingConfig();
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.errors?.[0]?.msg || error?.response?.data?.msg || 'The template save not successfully',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };
  return (
    <Card mt="14px" overflowX={{ sm: 'scroll', xl: 'hidden' }}>
      <CardHeader p="6px 0px 14px 0px">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          Setting Config
        </Text>
      </CardHeader>
      <CardBody>
        <Grid templateColumns="repeat(2, 1fr)">
          <GridItem w="100%">
            <Flex alignItems="center" flexDirection={"column"}>
              <CheckboxController
                styleContainer={{ display: 'flex', alignItems: 'center' }}
                styleBoxInput={{ flexShrink: '1', flexGrow: '1', flexBasis: '0', marginRight: 2 }}
                styleLabel={{ m: '0' }}
                control={control}
                name="zns"
                label="ZNS OFF"
                disabled={getSettingConfigLoading}
              />
              <CheckboxController
                styleContainer={{ display: 'flex', alignItems: 'center' }}
                styleBoxInput={{ flexShrink: '1', flexGrow: '1', flexBasis: '0', marginRight: 2 }}
                styleLabel={{ m: '0' }}
                control={control}
                name="tps"
                label="TPS OFF"
                disabled={getSettingConfigLoading}
              />
              <CheckboxController
                styleContainer={{ display: 'flex', alignItems: 'center' }}
                styleBoxInput={{ flexShrink: '1', flexGrow: '1', flexBasis: '0', marginRight: 2 }}
                styleLabel={{ m: '0' }}
                control={control}
                name="dtel"
                label="DTEL OFF"
                disabled={getSettingConfigLoading}
              />
            </Flex>

            <Flex mt="4" justifyContent="end">
              <Button variant="primary" maxH="30px" size="sm" isLoading={loading} onClick={handleSubmit(onSubmit)} mr={2}>
                Save
              </Button>
            </Flex>
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default SettingConfig;
