import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Input, Text, useToast, useColorModeValue, Grid, GridItem } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useAxios from 'axios-hooks';
import { isEmpty } from 'lodash';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import InputController from 'components/Form/InputController';
import { API_ROUTES } from 'utils/constant';
import { CheckIcon } from '@chakra-ui/icons';

const defaultValues = {
  templateId: '',
  id: '',
  status: false
};

const Template = () => {
  const toast = useToast();
  const textColor = useColorModeValue('gray.700', 'white');
  const { control, setValue, handleSubmit, reset, watch } = useForm({
    defaultValues,
  });
  const status = watch('status');
  const [{ loading: getSettingLoading }, getTemplateSetting] = useAxios(
    {
      url: API_ROUTES.ZaloSetting,
    },
    {
      manual: true,
    }
  );
  const [{ loading }, saveTemplateSetting] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ZaloSetting,
    },
    {
      manual: true,
    }
  );
  const [{ loading: activeTemplateStatus }, activeStatus] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ChangeStatusTemplate,
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    getTemplateSetting().then(res => {
      const data = res?.data?.data;

      if (!isEmpty(data)) {
        reset({ templateId: data?.templateId, id: data?._id, status: data?.status });
      }
    });
  }, []);

  const onSubmit = values => {
    saveTemplateSetting({ data: values })
      .then(() => {
        toast({
          title: 'The template save successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // getTemplateSetting();
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.errors?.[0]?.msg || error?.response?.data?.msg || 'The template save not successfully',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };
  const onActiveTemplate = () => {
    const templateData = {
      templateId: watch('id'),
    };
    activeStatus({ data: templateData })
      .then(() => {
        toast({
          title: 'Active Template Success',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // getTemplateSetting();
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.errors?.[0]?.msg || error?.response?.data?.msg || 'Active Template Fail',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };
  return (
    <Card mt="14px" overflowX={{ sm: 'scroll', xl: 'hidden' }}>
      <CardHeader p="6px 0px 14px 0px">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          Template
        </Text>
      </CardHeader>
      <CardBody>
        <Grid templateColumns="repeat(2, 1fr)">
          <GridItem w="100%">
            <Flex alignItems="center">
              <InputController
                styleContainer={{ display: 'flex', alignItems: 'center' }}
                styleBoxInput={{ flexShrink: '1', flexGrow: '1', flexBasis: '0', marginRight: 2 }}
                styleLabel={{ m: '0' }}
                control={control}
                name="templateId"
                label="Template ID"
                disabled={getSettingLoading}
              />
              {status && <CheckIcon color="green" />}
            </Flex>

            <Flex mt="4" justifyContent="end">
              <Button variant="primary" maxH="30px" size="sm" isLoading={loading} onClick={handleSubmit(onSubmit)} mr={2}>
                Save
              </Button>
              {!status && (
                <Button variant="primary" maxH="30px" size="sm" isLoading={loading} onClick={onActiveTemplate}>
                  Active
                </Button>
              )}
            </Flex>
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default Template;
